<script lang="ts" setup>
import { useTopLoadingStore } from '@/stores/loadingStore'

const topLoading = useTopLoadingStore()
</script>

<template>
  <div
    v-if="topLoading.loading"
    class="top-loading-bar"
    :style="{ width: `${topLoading.progress}%` }"
  >
    <!--    <div class="fixed top-2 h-screen w-screen flex flex-col bg-white items-center justify-center">-->
    <!--      <div class="flex flex-shrink-0 flex-col items-center">-->
    <!--        <img class="h-10 w-auto mb-2" src="@/assets/images/Logo.png" alt="Onplad Advertiser" />-->
    <!--      </div>-->
    <!--      <div class="loading__bar mt-4"></div>-->
    <!--    </div>-->
  </div>
</template>

<style lang="scss">
.top-loading-bar {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #366eff;
  transition: width 0.5s ease;
}
</style>
