import { defineStore } from 'pinia'

const ANIMATION_TIMING = 1000
export const useTopLoadingStore = defineStore('topLoading', {
  state: () => ({
    loading: false,
    progress: 0
  }),

  actions: {
    startLoading() {
      this.loading = true
      this.progress = 0
    },

    updateProgress(value: number) {
      if (this.progress > value) {
        //30>5
        for (let i = value; i == this.progress; ) {
          setTimeout(() => {
            this.progress--
          }, ANIMATION_TIMING)
        }
      } else {
        //5<30
        for (let i = this.progress; i <= value; i++) {
          setTimeout(() => {
            this.progress = i
          }, ANIMATION_TIMING)
        }
      }
    },

    finishLoading() {
      this.loading = false
      this.progress = 0
    }
  }
})
