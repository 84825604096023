import './assets/styles/style.scss'

import { ComponentPublicInstance, createApp } from 'vue'
import { createPinia } from 'pinia'
import Vue3Toasity, { Content, toast, type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import App from './App.vue'
import router from './router'
import { ErrorInvalid } from '@/utils/api'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'
import ErrorSubToast from '@/components/alerts/ErrorSubToast.vue'

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init(
    import.meta.env.VITE_POSTHOG
      ? import.meta.env.VITE_POSTHOG
      : 'phc_twtEMMYL3UZAdZCop4aNase1sMZn3kOMxJXfOdLaGLc',
    {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only'
    }
  )
}

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://e0f869384d6ce2158bd0a6f932270195@o4507769293176832.ingest.de.sentry.io/4507770759086160',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(createPinia())
app.use(router)
app.use(Vue3Toasity, {
  autoClose: 3000,
  theme: 'colored'
} as ToastContainerOptions)

app.config.errorHandler = (
  err: unknown | any,
  instance: ComponentPublicInstance | null,
  info: string
) => {
  if (err instanceof ErrorInvalid) {
    toast(<Content>ErrorSubToast, {
      type: 'error',
      position: 'top-left',
      data: {
        message: info ? info : err.message,
        error: err.message
      }
    })
  } else {
    throw new Error(err)
  }

  // console.error('Error:', err)
  // console.error('Error:', err instanceof ErrorInvalid)
  // console.error('Vue component:', instance)
  // console.error('Additional info:', info)
}

app.config.warnHandler = (msg, instance, trace) => {
  console.warn('Msg:', msg, 'Additional info:', trace, 'Vue component:', instance)
}

app.mount('#app')
posthog.capture('publisher.mount', {
  mount: true
})

posthog.register_once({
  'app.platform': 'publisher'
})
