<template>
  <div v-if="props.toastProps.data && props.toastProps.data?.error">
    {{ props.toastProps.data?.error }}
  </div>
  <div class="text-xs">{{ props.toastProps.data?.message }}</div>
</template>

<script setup lang="ts">
import type { ToastOptions } from 'vue3-toastify'

const props = defineProps<{
  toastProps: ToastOptions | any
}>()
</script>
